@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CreateAccount {
    &-InnerWrapper {
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.05em;
            color: $color-neutral-70;
            margin-bottom: 32px;
            min-height: 42px;
        }

        > div {
            margin: 0;
        }
    }

    .MyAccountOverlay {
        &-Legend {
            &:nth-of-type(1) {
                margin-bottom: 24px;
            }

            legend {
                padding: 0 0 24px;
            }
        }

        &-PasswordBlock {
            display: block;

            > div {
                width: 100%;
            }
        }
    }

    .ContentWrapper {
        @include page-container;
    }
}

@include mobileAndTablet {
    .CreateAccount {
        &-InnerWrapper {
            display: block;

            p {
                min-height: 0;
            }
        }
    }
}

@include mobileAndTablet {
    .CreateAccount {
        &-InnerWrapper {
            .CreateAccount-CreateAccountWrapper {
                margin-bottom: 40px;
            }
        }
    }
}

@include desktopAndAbove {
    .CreateAccount {
        &-SignInWrapper,
        &-CreateAccountWrapper {
            max-width: 560px;
        }

        .ContentWrapper {
            padding-bottom: 100px;
        }
    }
}
