@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountNewsletterSubscription {
    &-Button {
        margin-top: 32px;
    }
}

@include tablet {
    .MyAccountNewsletterSubscription {
        display: block;
    }
}
