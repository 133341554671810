@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOrderPopup {
    font-size: 14px;

    h4 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    td,
    th {
        min-width: 0;
    }

    dl {
        display: grid;
        margin-block-start: 12px;
        grid-template-columns: 1fr 1fr;
        margin-top: 10px;
        grid-gap: 10px;
        margin-bottom: 32px;
    }

    dt,
    dd {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.05em;
    }

    dt {
        font-weight: bold;
    }

    dd {
        color: $color-neutral-60;
    }

    &-ProductsWrapper,
    &-OrderWrapper {
        h4 {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
        }
    }

    &-ProductsWrapper {
        h4 {
            border-bottom: 1px solid $color-neutral-30;
            padding-bottom: 16px;
        }
    }

    &-Products {
        display: grid;
        width: 100%;
        margin-block-start: 12px;
        table-layout: initial;
        margin-block-end: 14px;

        td,
        th {
            vertical-align: middle;
        }
    }


    &-Row {
        display: grid;
        grid-template-columns: 110px 1fr 110px;
        grid-gap: 24px;
        height: auto;
        border-bottom: 1px solid $color-neutral-30;
        padding: 12px 0;

        &:nth-child(even) {
            img {
                mix-blend-mode: multiply;
            }
        }
    }

    &-Thumbnail {
        padding: 0;
        width: 100%;
        height: 82px;
    }

    &-Name {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        max-width: 160px;
    }

    &-Qty {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 12px;
        color: $color-neutral-50;
    }

    &-Price {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.05em;
        text-align: right;
    }

    &-Address {
        margin-block-end: 14px;

        .KeyValueTable-Wrapper {
            overflow-x: unset;
        }
    }

    &-Content {
        min-height: 200px;
    }
}

@include mobile {
    .MyAccountOrderPopup {
        .Popup-Heading {
            font-size: 24px;
            word-spacing: 100vw;
            margin-bottom: 32px;
        }

        &-Price {
            word-spacing: 20px;
            max-width: min-content;
            overflow-wrap: break-word;
            white-space: pre-line;
        }

        &-Row {
            grid-template-columns: 110px 1fr 56px;
        }

        h4 {
            font-size: 17px;
        }

        dl {
            margin-block-start: 14px;
            grid-gap: 7px;
            margin-block-end: 28px;
        }

        &-Products {
            margin-block-start: 14px;
            margin-block-end: 28px;
            overflow-x: unset!important;
        }

        &-Address {
            margin-block-end: 28px;
            overflow-x: unset!important;
        }
    }
}

@include desktop {
    .MyAccountOrderPopup {
        &-Content {
            display: grid;
            grid-gap: 24px;
        }

        &-Address, &-Products {
            margin-block-start: 24px;
        }
    }
}

@include desktopAndAbove {
    .MyAccountOrderPopup {
        .Popup-Content {
            min-width: 910px;
        }
    }
}
