@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --wishlist-item-border-color: var(--expandable-content-divider-color);
    --myaccount-wishlist-action-bar-offset: -83px;
}

.MyAccountMyWishlist {
    &-Products {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: max-content;
        grid-gap: 24px;
    }

    &-ActionBar {
        display: flex;
        padding-bottom: 32px;
    }

    &-ActionBarActionWrapper {
        display: flex;
        justify-content: space-between;
        margin-block-end: 10px;
    }

    .Button {
        &.MyAccountMyWishlist-ClearWishlistButton:not([disabled]) {
            padding: 0;
        }
    }

    &-NoProductsButton {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &-Button {
        .CartIcon {
            fill: var(--color-white);
            margin-inline-end: 12px;
            width: 20px;
            height: 20px;

            &:hover {
                fill: var(--color-white);
            }
        }
    }

    &-ClearRemoveItemsButton {
        text-transform: uppercase;
        color: initial;

        &.Button {
            width: auto;
        }
    }

    &-ShareWishlistButton {
        svg {
            fill: $color-primary-50;
        }
    }
}

@include mobileAndTablet {
    .MyAccountMyWishlist {
        .Button {
            &.MyAccountMyWishlist-ShareWishlistButton {
                width: 55px !important;
                padding: 0;
            }
        }
    }
}

@include tablet {
    .MyAccountMyWishlist {
        &-Products {
            padding-block-end: var(--myaccount-wishlist-action-bar-offset);
        }

        &-NoProducts {
            position: relative;
            top: 60px;
        }
    }
}

@include mobile {
    .MyAccountMyWishlist {
        &-Products {
            grid-column-gap: 28px;
            grid-template-columns: 1fr;
            border: 0;
            max-width: 100%;
            margin: 0 auto;
            margin-block-end: var(--myaccount-wihslist-products-margin-bottom);
            padding-top: 0;
        }

        &-ActionBar {
            background-color: var(--my-account-content-background);
            inset-block-end: var(--footer-total-height);
            flex-wrap: wrap;
            justify-content: space-between;
            inset-inline-start: 0;
            padding: 14px 14px;
            position: fixed;
            width: 100%;
            border-block-start: 1px solid var(--primary-divider-color);
            z-index: 10;
            bottom: 0;
        }

        .Button {
            &.MyAccountMyWishlist-ShareWishlistButton {
                width: var(--button-height);
                order: 3;
                margin-top: 16px;
                padding: 0;
            }

            &.MyAccountMyWishlist-Button {
                flex: 0 0 100%;
                order: 1;
                margin-top: 0;
            }

            &.MyAccountMyWishlist-ClearWishlistButton {
                width: calc(100% - var(--button-height) - 32px);
                order: 2;
            }
        }
    }
}

@include desktop {
    .MyAccountMyWishlist {
        &-ActionBar {
            display: flex;
            justify-content: flex-start;

            .Button {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        .Button {
            width: auto !important;

            + .Button {
                &.MyAccountMyWishlist-Button,
                &.MyAccountMyWishlist-ClearWishlistButton {
                    margin-top: 0;
                    margin-left: 24px;
                }
            }
        }
    }
}

@include above1480 {
    .MyAccountMyWishlist {
        &-Products {
            grid-template-columns: repeat(3, 1fr);
        }

        &-ActionBar {
            position: absolute;
            top: -87px;
            right: 0;
        }
    }
}
