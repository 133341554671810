@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --my-account-content-background: #{$white};
    --my-account-wrapper-margin-mobile: 18px;
    --my-account-content-border: 1px solid #{$color-neutral-30};
}

.MyAccount {
    background: var(--my-account-background);

    &-Wrapper {
        padding-block-start: 12px;
        padding-block-end: 12px;
        --content-wrapper-width: #{$page-max-width-cms};
    }

    &-TabContent {
        background: var(--my-account-content-background);
    }

    &-Heading {
        text-transform: none;
        margin: 0;
        margin-block-end: 36px;
        font-size: 30px;
        font-weight: 500;
        line-height: 42px;
    }

    &-SubHeading {
        color: var(--secondary-dark-color);
        font-size: 16px;
    }

    a:not(.Button) {
        color: $color-primary-50;
    }
}

@include mobileAndTablet {
    .MyAccount {
        &-Heading {
            margin-block-end: 28px;
            display: none;
        }

        .Button {
            width: 100%;

            + .Button {
                margin-top: 16px;
            }
        }
    }
}

@include narrow-desktop {
    .MyAccount {
        &-Wrapper {
            grid-column-gap: 32px;
        }
    }
}

@include desktopAndAbove {
    .MyAccount {
        &-Wrapper {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto 1fr;
            grid-row-gap: 0;
        }

        &-TabContent {
            grid-row: span 2;
        }

        .Button {
            + .Button {
                margin-top: 0;
                margin-left: 32px;
            }
        }
    }
}

@include wide-desktop {
    .MyAccount {
        &-Wrapper {
            grid-column-gap: 64px;
        }
    }
}
