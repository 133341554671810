@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountSignIn {
    &-Link {
        text-align: center;
        margin-bottom: 28px;

        .Button {
            margin: 0;
            font-size: 15px;
            color: $color-primary-50;
        }
    }

    &-Additional {
        padding-top: 24px;
    }
}
