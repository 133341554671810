@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LoginAccount {
    &-InnerWrapper {
        display: block;

        button:not(.Button_likeLink) {
            width: 100%;
        }

        p {
            color: $color-neutral-70;
            line-height: 20px;
        }

        > div {
            margin: 0;
        }
    }

    &-SignInWrapper,
    &-CreateAccount {
        max-width: 100%;
    }

    &-SignInWrapper {
        margin-top: 32px;
    }

    .Field {
        margin-bottom: 20px;
    }

    .ContentWrapper {
        @include page-container;
    }
}

@include tablet {
    .LoginAccount {
        button {
            margin: 0;
        }
    }
}

@include desktopAndAbove {
    .LoginAccount {
        &-InnerWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 100px;
        }

        &-SignInWrapper,
        &-CreateAccount {
            max-width: 560px;
        }

        .ContentWrapper {
            padding-bottom: 100px;
        }
    }
}
