@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountTabListItem {
    &-Button {
        font-size: 16px;
        line-height: 24px;
        padding: 12px 23px;
        width: 100%;
    }

    &_isActive,
    &:hover {
        .MyAccountTabListItem-Button {
            font-weight: 400;
            color: $color-primary-50;

            svg {
                fill: $color-primary-50;
            }
        }
    }
}

@include mobileAndTablet {
    .MyAccountTabListItem {
        &_isActive {
            display: none;
        }
    }
}
