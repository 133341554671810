@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@include desktopAndAbove {
    .MyAccountAddressPopup {
        .FieldForm-Fields {
            max-width: 612px;
            margin-right: -24px;
        }

        .Field-Wrapper {
            &:not(.Field-Wrapper_type_checkbox) {
                width: 50%;
                max-width: 318px;
                float: left;
                padding-right: 24px;

                &:nth-of-type(2n + 1) {
                    clear: both;
                }
            }
        }

        .FieldGroup-Wrapper + .FieldGroup-Wrapper {
            .Field-Wrapper {
                &:not(.Field-Wrapper_type_checkbox) {
                    &:nth-of-type(2n + 1) {
                        clear: none;
                    }
                }
            }
        }

        .FieldForm-Fields > .Field-Wrapper {
            &:last-child {
                clear: none;
            }
        }

        .FieldForm-Fields::after {
            clear: both;
            display: table;
            content: '';
        }
    }
}
