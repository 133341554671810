@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@include mobileAndTablet {
    .MyAccountAddressBook {
        &-Button {
            position: static;
        }
    }
}

@include desktopAndAbove {
    .MyAccountAddressBook {
        &-Button {
            top: -78px;
        }
    }
}
