@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountAddressForm {
    &-ExtraInfo {
        color: $color-neutral-50;
        letter-spacing: 0.5px;
        margin-bottom: 24px;
    }
}
