@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --my-account-overlay-width: 460px;
}

.MyAccountOverlay {
    border: none;
    right: -80px;
    top: 65px;

    &::before {
        content: '';
        background: $color-neutral-90;
        width: 100%;
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.25;
        transform: none;
        border: 0;
    }

    &::after {
        content: '';
        position: absolute;
        width: 58px;
        height: 66px;
        transform: none;
        right: 34px;
        top: -65px;
        background: $color-neutral-5;

        @include desktop {
            inset-inline-end: 42px;
        }
    }

    &-AdditionalLabel {
        font-size: 15px;
        padding-right: 9px;
    }

    .Button_likeLink {
        font-size: 15px;
        color: $color-primary-50;
    }

    &-Action {
        padding: 30px;
    }

    .Field {
        margin-bottom: 16px;

        &-LabelContainer {
            display: none;
        }
    }

    .TypographyHeader {
        margin-bottom: 16px;
    }

    .MyAccountSignIn {
        &-Link {
            text-align: left;
        }
    }

    &-PasswordBlock {
        display: flex;
        justify-content: space-between;

        > div {
            width: 48%;
        }
    }
}

@include mobile {
    .MyAccountOverlay {
        &-PasswordBlock {
            display: block;

            > div {
                width: 100%;
            }
        }
    }
}
