@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountDashboard {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 64px;

    &-Info {
        margin-block-end: 5px;
    }

    &-AddressBook {
        margin-top: 20px;
    }
}

@include mobile {
    .MyAccountDashboard {
        grid-gap: 56px;

        &-DefaultAddress .KeyValueTable {
            margin-bottom: 0;
        }
    }
}

@include desktopAndAbove {
    .MyAccountDashboard {
        grid-template-columns: 1fr 1fr;

        &-CustomerData {
            grid-column: span 2;

            .Button {
                width: 220px;
            }
        }

        &-DefaultAddress {
            grid-row: 2;
        }
    }
}

@include narrow-desktop {
    .MyAccountDashboard {
        grid-column-gap: 32px;
    }
}

@include wide-desktop {
    .MyAccountDashboard {
        grid-column-gap: 140px;
    }
}
